<template>
  <div>
    <portal to="subheader-left">
      <div class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
        <h5 class="text-dark font-weight-bold my-5">{{ title }}</h5>
        <slot name="title-right"></slot>
        <div class="mr-5"></div>
        <div class="subheader-separator subheader-separator-ver mt-4 mb-2 mr-5 bg-gray-200"></div>

        <b-icon
          v-if="showFilterButton"
          data-menu-toggle="hover"
          icon="filter"
          variant="dark"
          scale="1"
          class="filter rounded btn-hover-text-primary mr-5"
          :class="{ filterActive }"
          @click="onClickFilterButton"
        ></b-icon>

        <slot name="toolbar-left"></slot>
      </div>
    </portal>
    <portal to="subheader-right">
      <slot name="toolbar-right"></slot>
    </portal>
    <div class="d-flex flex-column flex-lg-row">
      <div
        class="d-flex flex-column flex-lg-row-auto w-100 w-lg-300px w-xl-400px mb-sm-5"
        :class="{ 'slide-out': !filterActive, 'slide-in': filterActive }"
      >
        <BlockIfLoading :isInitiallyLoading="isInitiallyLoading">
          <div
            class="card card-filter-nav d-flex flex-column card-custom"
            :style="{ 'max-height': filterHeight + 'px' }"
          >
            <perfect-scrollbar class="scroll">
              <div class="card-body overflow-y-auto">
                <div class="d-flex justify-content-center">
                  <slot name="filter"></slot>
                </div>
              </div>
            </perfect-scrollbar>
          </div>
        </BlockIfLoading>
      </div>

      <div class="flex-lg-row-fluid" :class="filterActive ? 'ml-lg-3 ml-xl-5' : 'transition'">
        <div class="card p-0" v-if="isInitiallyLoading" :style="getTableHeightInlineStyle()">
          <div class="card-body d-flex flex-column justify-content-between">
            <b-skeleton-table :rows="calculateSkeletonRows()" :columns="6"></b-skeleton-table>
            <b-skeleton height="35px" width="25%"></b-skeleton>
          </div>
        </div>
        <slot
          v-else
          name="table"
          :tableHeight="getBodyHeight()"
          :heightComponentInlineStyle="heightComponentInlineStyle()"
        ></slot>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.card-filter-nav {
  overflow-y: auto;
}

.filter {
  width: 40px;
  height: 40px;
  cursor: pointer;
  padding: 4px;
}

.filter:hover {
  color: rgb(54, 153, 255) !important;
  background-color: rgb(245, 245, 249);
}

.filterActive {
  box-shadow: inset 1px 1px 2px #717a8e42;
  transform: translateY(1px);
  color: rgb(54, 153, 255) !important;
  background-color: rgb(245, 245, 249);
}
.slide-out {
  transform: translateX(-150%);
  width: 0px !important;
  height: 0px !important;
}

.slide-in {
  transform: translateX(0);
}

.transition {
  width: auto;
  transition: width 2s linear;
}
</style>
<script>
import EventBus from '@/core/common/plugins/event-bus.js';
import BlockIfLoading from '@/components/common/block-if-loading.vue';

export default {
  name: 'FilterOverviewComponent',
  components: {
    BlockIfLoading,
  },
  props: {
    title: { type: String },
    isInitiallyLoading: { type: Boolean },
    showFilterButton: { type: Boolean, default: false },
    showFilterInitially: { type: Boolean, default: true, required: false },
  },
  data() {
    return {
      filterHeight: 'auto',
      filterActive: true,
    };
  },
  computed: {},
  created() {
    window.addEventListener('resize', this.onResizeWindow);
  },
  destroyed() {
    window.removeEventListener('resize', this.onResizeWindow);
  },
  mounted() {
    EventBus.$on('reload', () => {
      this.$emit('reload');
    });
    this.onResizeWindow();
    this.filterActive = this.showFilterInitially;
  },
  beforeDestroy() {
    EventBus.$off('reload');
  },
  methods: {
    onResizeWindow() {
      this.filterHeight = this.getBodyHeight() + 80;
    },
    getBodyHeight() {
      const subheaderBottom = document.getElementById('kt_subheader')?.getBoundingClientRect().bottom;
      const contentTop = document.getElementById('kt_content')?.getBoundingClientRect().top;
      const bodyHeight = window.innerHeight - subheaderBottom - contentTop;
      return isNaN(bodyHeight) ? 0 : bodyHeight;
    },
    getTableHeightInlineStyle() {
      const bodyHeight = this.getBodyHeight() + 80;
      return { height: bodyHeight + 'px' };
    },
    onClickFilterButton() {
      this.filterActive = !this.filterActive;
    },
    calculateSkeletonRows() {
      const bodyHeight = this.getBodyHeight();
      const skeletonHeaderHeight = 40.75;
      const skeletonRowHeight = 36.75;

      const skeletonRows = Math.floor((bodyHeight - skeletonHeaderHeight) / skeletonRowHeight) - 1;

      return Math.max(1, skeletonRows);
    },
    heightComponentInlineStyle() {
      return 'height:' + (this.getBodyHeight() + 80) + 'px';
    },
  },
};
</script>
