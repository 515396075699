<template>
  <div :class="{ 'overlay overlay-block': isLoading }">
    <slot></slot>
    <div v-if="isLoading" class="overlay-layer bg-dark-o-20">
      <div class="spinner spinner-primary"></div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.overlay-layer {
  z-index: 1;
}
</style>
<script>
export default {
  name: 'BlockIfLoading',
  props: {
    isLoading: {
      type: Boolean,
    },
  },
};
</script>
